var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"valuesList pl-5"},[_c('div',{staticClass:"accordion col",attrs:{"role":"tablist"}},[_c('div',{staticClass:"row pl-0"},[_c('div',{staticClass:"col-12 col-lg-12 pl-0 pr-0"},[_c('div',{staticClass:"col-12 col-lg-12 pl-0 pr-0"},[_c('b-card',{staticClass:"mb-1 pl-0 pr-0",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-0",attrs:{"header-tag":"header","role":"tab"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.accordion-1",modifiers:{"accordion-1":true}}],staticClass:"p-0",attrs:{"block":"","variant":"info"}},[_c('div',{staticClass:"d-flex tabsIcons p-0"},[_c('div',[_c('img',{attrs:{"alt":_vm.lang == 'ka'
                          ? _vm.removeHTML(_vm.items.courageTitleKA)
                          : _vm.removeHTML(_vm.items.courageTitleEN),"src":(_vm.server_url + "/" + (_vm.items.courageImage))}})]),_c('div',[_c('p',{staticClass:"blackColor text-left titleP pl-3 mt-3",domProps:{"innerHTML":_vm._s(
                        _vm.lang == 'ka'
                          ? _vm.items.courageTitleKA
                          : _vm.items.courageTitleEN
                      )}})])])])],1),_c('b-collapse',{attrs:{"id":"accordion-1","visible":"","accordion":"my-accordion","role":"tabpanel"}},[_c('b-card-body',[_c('b-card-text',[_c('div',{domProps:{"innerHTML":_vm._s(
                      _vm.lang == 'ka'
                        ? _vm.items.courageDescriptionKA
                        : _vm.items.courageDescriptionEN
                    )}})])],1)],1)],1)],1),_c('div',{staticClass:"col-12 col-lg-12 pl-0 pr-0"},[_c('b-card',{staticClass:"mb-1",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-0",attrs:{"header-tag":"header","role":"tab"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.accordion-2",modifiers:{"accordion-2":true}}],staticClass:"p-0",attrs:{"block":"","variant":"info"}},[_c('div',{staticClass:"d-flex tabsIcons p-0"},[_c('div',[_c('img',{attrs:{"alt":_vm.lang == 'ka'
                          ? _vm.removeHTML(_vm.items.wiseSolutionsTitleKA)
                          : _vm.removeHTML(_vm.items.wiseSolutionsTitleEN),"src":(_vm.server_url + "/" + (_vm.items.wiseSolutionsImage))}})]),_c('div',[_c('p',{staticClass:"titleP text-left blackColor pl-3 mt-3",domProps:{"innerHTML":_vm._s(
                        _vm.lang == 'ka'
                          ? _vm.items.wiseSolutionsTitleKA
                          : _vm.items.wiseSolutionsTitleEN
                      )}})])])])],1),_c('b-collapse',{attrs:{"id":"accordion-2","visible":"","accordion":"my-accordion","role":"tabpanel"}},[_c('b-card-body',[_c('b-card-text',[_c('div',{domProps:{"innerHTML":_vm._s(
                      _vm.lang == 'ka'
                        ? _vm.items.wiseSolutionsDescriptionKA
                        : _vm.items.wiseSolutionsDescriptionEN
                    )}})])],1)],1)],1)],1)]),_c('div',{staticClass:"col-12 col-lg-12 pl-0 pr-0 pr-0"},[_c('div',{staticClass:"col-12 col-lg-12 pl-0 pr-0"},[_c('b-card',{staticClass:"mb-1",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-0",attrs:{"header-tag":"header","role":"tab"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.accordion-3",modifiers:{"accordion-3":true}}],staticClass:"p-0",attrs:{"block":"","variant":"info"}},[_c('div',{staticClass:"d-flex tabsIcons"},[_c('div',[_c('img',{attrs:{"alt":_vm.lang == 'ka'
                          ? _vm.removeHTML(_vm.items.responsibilityTitleKA)
                          : _vm.removeHTML(_vm.items.responsibilityTitleEN),"src":(_vm.server_url + "/" + (_vm.items.responsibilityImage))}})]),_c('div',[_c('p',{staticClass:"titleP text-left blackColor pl-3 mt-3",domProps:{"innerHTML":_vm._s(
                        _vm.lang == 'ka'
                          ? _vm.items.responsibilityTitleKA
                          : _vm.items.responsibilityTitleEN
                      )}})])])])],1),_c('b-collapse',{attrs:{"id":"accordion-3","accordion":"my-accordion","role":"tabpanel","selected":""}},[_c('b-card-body',[_c('b-card-text',[_c('div',{domProps:{"innerHTML":_vm._s(
                      _vm.lang == 'ka'
                        ? _vm.items.responsibilityDescriptionKA
                        : _vm.items.responsibilityDescriptionEN
                    )}})])],1)],1)],1)],1),_c('div',{staticClass:"col-12 col-lg-12 pl-0 pr-0"},[_c('b-card',{staticClass:"mb-1",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-0",attrs:{"header-tag":"header","role":"tab"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.accordion-4",modifiers:{"accordion-4":true}}],staticClass:"p-0",attrs:{"block":"","variant":"info"}},[_c('div',{staticClass:"tabsIcons d-flex"},[_c('div',[_c('img',{attrs:{"alt":_vm.lang == 'ka'
                          ? _vm.removeHTML(_vm.items.supportTitleKA)
                          : _vm.removeHTML(_vm.items.supportTitleEN),"src":(_vm.server_url + "/" + (_vm.items.supportImage))}})]),_c('div',[_c('p',{staticClass:"titleP text-left blackColor pl-3 mt-3",domProps:{"innerHTML":_vm._s(
                        _vm.lang == 'ka'
                          ? _vm.items.supportTitleKA
                          : _vm.items.supportTitleEN
                      )}})])])])],1),_c('b-collapse',{attrs:{"id":"accordion-4","accordion":"my-accordion","role":"tabpanel"}},[_c('b-card-body',[_c('b-card-text',[_c('div',{domProps:{"innerHTML":_vm._s(
                      _vm.lang == 'ka'
                        ? _vm.items.supportDescriptionKA
                        : _vm.items.supportDescriptionEN
                    )}})])],1)],1)],1)],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }