<template>
  <div class="valuesList pl-5">
    <div class="accordion col" role="tablist">
      <div class="row pl-0">
        <div class="col-12 col-lg-12 pl-0 pr-0">
          <div class="col-12 col-lg-12 pl-0 pr-0">
            <b-card no-body class="mb-1 pl-0 pr-0">
              <b-card-header header-tag="header" class="p-0" role="tab">
                <b-button
                  block
                  v-b-toggle.accordion-1
                  variant="info"
                  class="p-0"
                >
                  <div class="d-flex tabsIcons p-0">
                    <div>
                      <img
                        :alt="
                          lang == 'ka'
                            ? removeHTML(items.courageTitleKA)
                            : removeHTML(items.courageTitleEN)
                        "
                        :src="`${server_url}/${items.courageImage}`"
                      />
                    </div>
                    <div>
                      <p
                        v-html="
                          lang == 'ka'
                            ? items.courageTitleKA
                            : items.courageTitleEN
                        "
                        class="blackColor text-left titleP pl-3 mt-3"
                      ></p>
                    </div>
                  </div>
                </b-button>
              </b-card-header>
              <b-collapse
                id="accordion-1"
                visible
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text
                    ><div
                      v-html="
                        lang == 'ka'
                          ? items.courageDescriptionKA
                          : items.courageDescriptionEN
                      "
                    ></div>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>

          <div class="col-12 col-lg-12 pl-0 pr-0">
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-0" role="tab">
                <b-button
                  block
                  v-b-toggle.accordion-2
                  variant="info"
                  class="p-0"
                >
                  <div class="d-flex tabsIcons p-0">
                    <div>
                      <img
                        :alt="
                          lang == 'ka'
                            ? removeHTML(items.wiseSolutionsTitleKA)
                            : removeHTML(items.wiseSolutionsTitleEN)
                        "
                        :src="`${server_url}/${items.wiseSolutionsImage}`"
                      />
                    </div>
                    <div>
                      <p
                        v-html="
                          lang == 'ka'
                            ? items.wiseSolutionsTitleKA
                            : items.wiseSolutionsTitleEN
                        "
                        class="titleP text-left blackColor pl-3 mt-3"
                      ></p>
                    </div>
                  </div>
                </b-button>
              </b-card-header>
              <b-collapse
                id="accordion-2"
                visible
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text
                    ><div
                      v-html="
                        lang == 'ka'
                          ? items.wiseSolutionsDescriptionKA
                          : items.wiseSolutionsDescriptionEN
                      "
                    ></div>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </div>
        <div class="col-12 col-lg-12 pl-0 pr-0 pr-0">
          <div class="col-12 col-lg-12 pl-0 pr-0">
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-0" role="tab">
                <b-button
                  block
                  v-b-toggle.accordion-3
                  variant="info"
                  class="p-0"
                >
                  <div class="d-flex tabsIcons">
                    <div>
                      <img
                        :alt="
                          lang == 'ka'
                            ? removeHTML(items.responsibilityTitleKA)
                            : removeHTML(items.responsibilityTitleEN)
                        "
                        :src="`${server_url}/${items.responsibilityImage}`"
                      />
                    </div>
                    <div>
                      <p
                        v-html="
                          lang == 'ka'
                            ? items.responsibilityTitleKA
                            : items.responsibilityTitleEN
                        "
                        class="titleP text-left blackColor pl-3 mt-3"
                      ></p>
                    </div>
                  </div>
                </b-button>
              </b-card-header>
              <b-collapse
                id="accordion-3"
                accordion="my-accordion"
                role="tabpanel"
                selected
              >
                <b-card-body>
                  <b-card-text
                    ><div
                      v-html="
                        lang == 'ka'
                          ? items.responsibilityDescriptionKA
                          : items.responsibilityDescriptionEN
                      "
                    ></div>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>

          <div class="col-12 col-lg-12 pl-0 pr-0">
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-0" role="tab">
                <b-button
                  block
                  v-b-toggle.accordion-4
                  variant="info"
                  class="p-0"
                >
                  <div class="tabsIcons d-flex">
                    <div>
                      <img
                        :alt="
                          lang == 'ka'
                            ? removeHTML(items.supportTitleKA)
                            : removeHTML(items.supportTitleEN)
                        "
                        :src="`${server_url}/${items.supportImage}`"
                      />
                    </div>
                    <div>
                      <p
                        v-html="
                          lang == 'ka'
                            ? items.supportTitleKA
                            : items.supportTitleEN
                        "
                        class="titleP text-left blackColor pl-3 mt-3"
                      ></p>
                    </div>
                  </div>
                </b-button>
              </b-card-header>
              <b-collapse
                id="accordion-4"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text
                    ><div
                      v-html="
                        lang == 'ka'
                          ? items.supportDescriptionKA
                          : items.supportDescriptionEN
                      "
                    ></div>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import env from "../../../env.json";
export default {
  data() {
    return {
      lang: "ka",
      data: {},
      items: {},
      server_url: env.server_url,
    };
  },

  methods: {
    removeHTML: function (string) {
      return string.replace(/<\/?[^>]+>/gi, " ");
    },
  },
  mounted() {
    axios
      .get(`${env.host}/get/informations/values`)
      .then((result) => {
        this.items = result.data.item;
      })

      .catch((err) => console.log(err));
    if (localStorage.getItem("lang")) {
      this.lang = localStorage.getItem("lang");
    }
  },
};
</script>

<style scoped>
* {
  color: #666666;
}
.valuesList {
  width: 100%;
  padding: 0px;
  margin: 0px;
}

.card-body {
  padding-right: 0px;
  padding-top: 0px;
}

.titleP {
  width: 300px;
}

.accordion img {
  width: 40px;
}
::v-deep .btn-info {
  background-color: white;
  border-color: white;
}

::v-deep .btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #ffffff;
  border-color: #ffffff;
}

::v-deep .card {
  border: none !important;
}

::v-deep .btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: none;
}

::v-deep .btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0);
}

::v-deep .card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  display: flex;
  /* margin: auto; */

  /* border-bottom: 0px; */
  background-color: rgba(0, 0, 0, 0);
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}

.tabsIcons {
  width: 50px;
  /* border: 2px solid red; */
}

/* @media all and (max-width: 991px) {
  .tabsIcons {
    justify-content: center !important;
  }
} */

@media all and (max-width: 1500px) {
  .valuesList {
    padding-left: 0px !important;
    padding-right: 0px !important ;
  }
}

@media all and (max-width: 992px) {
  .valuesList {
    padding-left: 20px;
    padding-right: 20px;
    /* border: 2px solid red; */
  }
}

@media all and (max-width: 576px) {
  .titleP {
    width: auto;
  }
}
</style>
