<template>
  <div>
    <Navbar />
    <div class="mianContainer companyMiss">
      <div
        class="photoSide"
        v-bind:style="{
          backgroundImage: 'url(' + server_url + '/' + items.image + ')',
        }"
      ></div>
      <div class="textSide align-items-center">
        <ValuesList />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "./../../../components/Navbar/newNavbar/Navbar.vue";
import Footer from "./../../../components/Footer/newFooter/Footer.vue";
import ValuesList from "./ValuesList.vue";
import axios from "axios";
import env from "../../../env.json";

export default {
  name: "company",
  components: {
    Navbar,
    Footer,
    ValuesList,
  },
  data() {
    return {
      isLoading: false,
      data: {},
      items: {},
      server_url: env.server_url,
    };
  },
  mounted() {
    this.isLoading = true;
    axios
      .get(`${env.host}/get/informations/values`)
      .then((result) => {
        console.log(result);
        this.items = result.data.item;
        this.isLoading = false;
      })

      .catch((err) => console.log(err));
  },
};
</script>

<style scoped>
.mianContainer {
  width: 100%;
  margin: auto;
  padding: 0px;
  max-width: 1400px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: calc(100vh - 150px);
}
.photoSide {
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.photoSide img {
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.textSide {
  align-items: center;
  display: flex;
  padding: 40px;
}
.text {
  font-size: 16px;
  margin-top: -20px;
}

.companyMiss h1 {
  font-weight: bold;
  font-family: mtavruli;
}
.companyMiss h1 {
  color: #734c8a;
}

@media all and (max-width: 1500px) {
  .textSide {
    padding: 20px !important;
  }
}

@media all and (max-width: 992px) {
  .mianContainer {
    grid-template-columns: 1fr;
  }
  .photoSide {
    height: 100vw;
  }

  .textSide {
    padding: 0px;
  }
}
</style>
